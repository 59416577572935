import React from 'react';
import './SearchPage.css';
import { useStateValue } from './StateProvider';
import useGoogleSearch from './useGoogleSearch';
import Response from './response';
import { Link } from 'react-router-dom';
import Search from './Search'

function SearchPage() {
  const [{ term }, dispatch] = useStateValue();
  const { data } = useGoogleSearch(term);

  // const data = Response;

  console.log(data);

  return (
    <div className='searchPage'>
      <div className='searchPage__header'>
        <Link to="/">
          <img className='searchPage__logo' src='https://storage.googleapis.com/10xcoderkids.com/Helloo%20Logo.png' alt='' />
        </Link>

        <div className='searchPage_headerBody'>
          <Search hideButtons />
        </div>
      </div>
      
      { term && (
        <div className='searchPage__results'>
          <p className='searchPage__resultCount'>
            About {data?.searchInformation.formattedTotalResults} results ({data?.searchInformation.formattedSearchTime} seconds) for {term}
          </p>

          {data?.items.map(item => (
            <div className='searchPage_result'>
              <a href={item.link}>
	{item.pagemap?.cse_image?.length > 0 && item.pagemap?.cse_image[0]?.src && (
	  <img className='searchPage__resultImage' src={item.pagemap?.cse_image[0]?.src} alt='' />
	)}

                {item.displayLink}
              </a>
              <a className='searchPage_resultTitle' href={item.link}>
                <h2>{item.title}</h2>
              </a>
              <p className='searchPage_resultSnippet'>{item.snippet}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default SearchPage