import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import Search from './Search'

function Home() {
  return(
    <div className='home'>
      <div className='home__body'>
        <img src='https://storage.googleapis.com/10xcoderkids.com/Helloo%20Logo.png'  alt=''/>
        <div className='home__inputContainer'>
          <Search />
        </div>
      </div>
    </div>
  )
}

export default Home;