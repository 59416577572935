import React, { useState } from 'react'
import './Search.css'
import SearchIcon from '@material-ui/icons/Search';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useStateValue } from './StateProvider';
import { actionTypes } from './reducer';

function Search({ hideButtons = false }){
  const [{}, dispatch] = useStateValue();

  const [input, setInput] = useState('')
  const history = useHistory();

  const search = e => {
    e.preventDefault();

    dispatch({
      type: actionTypes.SET_SEARCH_TERM,
      term: input
    })

    history.push('/search?q=%s');
  }

  return (
    <form className='search'>
      <div className='search__input'>
        <SearchIcon className='search__inputIcon' />
        <input value={input} onChange={e => setInput(e.target.value)} placeholder={'Search Helloo or Type a URL'}/>
      </div>

      {!hideButtons ? (
        <div>
          <div className='search__buttons'>
            <Button type='submit' onClick={search} variant='outlined'>Helloo Search</Button>
            <Button variant='outlined'>I'm Feeling Lucky</Button>
          </div>
          <div className='search__h5'>
            <h5>Helloo offered in: हिन्दी বাংলা తెలుగు मराठी தமிழ் ગુજરાતી ಕನ್ನಡ മലയാളം ਪੰਜਾਬੀ</h5>
          </div>
        </div>
      ): (
        <div>
          <div className='search__buttons'>
            <Button className='search__buttonsHidden' type='submit' onClick={search} variant='outlined'>Helloo Search</Button>
            <Button className='search__buttonsHidden' variant='outlined'>I'm Feeling Lucky</Button>
          </div>
          <div className='search__h5'>
            <h5 className='search__h5Hidden'>Helloo offered in: हिन्दी বাংলা తెలుగు मराठी தமிழ் ગુજરાતી ಕನ್ನಡ മലയാളം ਪੰਜਾਬੀ</h5>
          </div>
        </div>
      )}
    </form>
  )
}

export default Search